.CarpTaxDecAgenda  {
    color: #330505;
    font-size: 16px;
}
.CarpTaxDecAgenda b {
    font-weight: 1000;
}
.CarpTaxDecAgenda th {
    font-weight: 1000;
    font-size: 17px;
}